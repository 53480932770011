<template>
  <div class="main_container">
    <h2 class="page_title text-center">Import États Comptables</h2>
    <div class="inner_container"></div>
  </div>
</template>
<style>
  @import './operationsBancaires.css';
</style>
<script>
export default {
  name: "ImportEtatsComptables"
}
</script>
